import axios from 'axios';
import { REACT_APP_X_API_KEY, REACT_APP_API_BASE_URL } from '../config';
import { compressImage } from '../helpers/compressor';

export const participateInContest = async ({ firstName, lastName, phoneNumber, wilaya, link, source, image }) => {
  // Create a new FormData object
  const formData = new FormData();

  // compress the image at hand
  const compressedImage = await compressImage(image);

  // Append the form data to the FormData object
  formData.append('first_name', firstName);
  formData.append('last_name', lastName);
  formData.append('phone_number', phoneNumber);
  formData.append('wilaya', wilaya);
  formData.append('address', wilaya);
  formData.append('link', link);
  formData.append('source', source);
  formData.append('image', compressedImage);

  // Set the headers for the request
  const headers = {
    'Content-Type': 'multipart/form-data',
    'x-api-key': REACT_APP_X_API_KEY
  };

  // Send the POST request using Axios
    try {
      const response = await axios.post(`${REACT_APP_API_BASE_URL}/api/participant`, formData, { headers });
      return response.data;
    } catch (error) {
      console.log(error);
    }
}