import React, { useEffect, useState } from 'react'
import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'
import './home.css'
import ContestForm from '../components/form'
import { getActiveContest } from '../api/contests'
import { CircularProgress } from '@mui/joy'

const Home = (props) => {
  const result = getActiveContest();
  const [isFetching, setIsFetching] = useState(false);
  const [contest, setContest] = useState(null);
  console.log(result.message);

  const initPage = async () => {
    const result = await getActiveContest();
    if(result?.message === 'ok') {
      setContest(result?.data);
    }
  }

  useEffect(() => {
    setIsFetching(true);
    initPage();
    setIsFetching(false);
  },[]);

  return (
    <div className="home-container">
      <Helmet>
        <title>Wafa Faile</title>
        <meta property="og:title" content="Wafafaille" />
      </Helmet>
      <section className="home-hero" id="home-top">
        <div className="home-menu">
          <div id="mobile-menu" className="home-mobile-navigation">
            <img
              alt="Wafafaille"
              src="/playground_assets/logo-main.png"
              className="home-logo"
            />
            <div className="home-links">
                <a href="#home-top"> <span className="Link">Accueil</span> </a>
                <a href='http://www.wafafaile.net' target='_blank'><span className="Link">Notre site</span> </a>
                <a href='#comment-participer'><span className="Link">Concours</span></a>
            </div>
            <div id="close-mobile-menu" className="home-close-mobile-menu">
              <svg viewBox="0 0 804.5714285714286 1024" className="home-icon">
                <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
              </svg>
            </div>
          </div>
          <div className="home-desktop-navigation">
            <nav className="home-centered">
              <div className="home-left">
                <img
                  alt="Wafafaille"
                  src="/playground_assets/logo-main.png"
                  className="home-logo1"
                />
                <div className="home-links1">
                  <a href="#home-top"> <span className="Link">Accueil</span> </a>
                  <a href='http://www.wafafaile.net' target='_blank'><span className="Link">Notre site</span> </a>
                  <a href='#comment-participer'><span className="Link">Concours</span></a>
                </div>
              </div>
              <div className="home-right">
                <div className="home-get-started">
                  <a href='#participation-form'>
                    <span className="home-text06">Participer au concours</span>
                  </a>
                </div>
                <div id="open-mobile-menu" className="home-burger-menu">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/pastedimage-yxbd.svg"
                    className="home-mobile-menu-button"
                  />
                </div>
              </div>
            </nav>
          </div>
          <div>
            <DangerousHTML
              html={`<script>
/*
Mobile menu - Code Embed
*/

// Mobile menu
const mobileMenu = document.querySelector("#mobile-menu")

// Buttons
const closeButton = document.querySelector("#close-mobile-menu")
const openButton = document.querySelector("#open-mobile-menu")

// On openButton click, set the mobileMenu position left to -100vw
openButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(0%)"
})

// On closeButton click, set the mobileMenu position to 0vw
closeButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(100%)"
})
</script>`}
            ></DangerousHTML>
          </div>
        </div>
        <header className="home-header">
          <h1 className="home-text07">Rejoignez le plaisir</h1>
          <p className="home-text08">
            Participez à notre concours, gagnez des prix incroyables ! soumettez des candidatures via notre plateforme et restez à jour. Inscrivez-vous maintenant pour gagner gros !
          </p>
          <div className="home-get-started1">
            <a href='#participation-form'>
              <span className="home-text09">Participer</span>
            </a>
          </div>
        </header>
        <div className="home-dashboard-preview">
          <div className="home-outline">
            <div className='home-animation-container'>
              <span>
                <img
                  alt="Animated Gift"
                  src="/playground_assets/home-animation.gif"
                  loading="lazy"
                  className="home-image"
                />
              </span>
            </div>
            {/* <iframe src="https://embed.lottiefiles.com/animation/110625"></iframe> */}
          </div>
        </div>
      </section>
      <section className="home-features" id="comment-participer">
        <div className="home-title">
          <span className="home-text10">
            <span>
              Comment participer au concours
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
          <span className="home-text14">
            <span>
              Suivez ces quatre étapes simples pour participer et augmenter vos chances de gagner.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
        <div className="home-cards">
          <div className="home-container1">
            <div className="card">
              <img
                alt="pastedImage"
                src="/playground_assets/pastedimage-vyi5-200h.png"
                className="home-icon2"
              />
              <span className="home-text17">Completez le Formulaire</span>
              <span className="home-text18">
                Commencez par remplir le formulaire d'inscription avec des informations personnelles précises, y compris votre nom, votre adresse et votre numéro de téléphone.
              </span>
            </div>
            <div className="home-publish card">
              <img
                alt="pastedImage"
                src="/playground_assets/pastedimage-mimg-200h.png"
                className="home-icon3"
              />
              <span className="home-text19">Télécharger une image</span>
              <span className="home-text20">
                Pour soumettre votre candidature, vous devrez télécharger votre image en suivant les instructions du site Web.
              </span>
            </div>
          </div>
          <div className="home-container2">
            <div className="card home-analyze">
              <img
                alt="pastedImage"
                src="/playground_assets/pastedimage-l6p-200h.png"
                className="home-icon4"
              />
              <span className="home-text21">Envoyer les données</span>
              <span className="home-text22">
              Une fois que vous avez rempli le formulaire et téléchargé votre image, cliquez sur le bouton "soumettre" pour envoyer vos données aux organisateurs du concours.
              </span>
            </div>
            <div className="card">
              <img
                alt="pastedImage"
                src="/playground_assets/pastedimage-fii6m-200h.png"
                className="home-icon5"
              />
              <span className="home-text23">Attendre les résultats</span>
              <span className="home-text24">
              Après avoir soumis votre candidature, vous devrez attendre que les organisateurs du concours examinent votre candidature et annoncent les gagnants.
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="home-how-it-works" id="participation-form">
        <div className="home-centered-container2">
          <div className="home-heading1">
            <span className="home-text46">Formulaire de participation</span>
            <span className="home-text47 title">
              Remplissez le formulaire d'inscription pour participer au concours.
            </span>
          </div>
          <div  className="contest-form-container">
            {
              isFetching ?
              <CircularProgress color="primary" size="lg" />
              : (
                !contest ?
                <div className='no-contest-found'>
                  <img
                    alt="Animated Gift"
                    src="/playground_assets/contest-not-found.png"
                    loading="lazy"
                    className="home-image"
                  />
                  <span className="home-text17" style={{textAlign:'center'}}>Pas de concours disponible pour le moment</span>
              </div>
                : <ContestForm name={contest?.name} />
              )
            }
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home
