import axios from 'axios';
import { REACT_APP_X_API_KEY, REACT_APP_API_BASE_URL } from '../config';


export const getActiveContest = async () => {
    try {
        const response = await axios.get(`${REACT_APP_API_BASE_URL}/api/contest/active`, {
            headers : {
                'x-api-key' : REACT_APP_X_API_KEY,
                'Accept':'*'
            }
        });
        return response.data;
      } catch (error) {
        console.log(error);
    }
};
