import Compressor from 'compressorjs';

export async function compressImage(file) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 1920,
      maxHeight: 1920,
      success(result) {
        const maxSize = 1.7 * 1024 * 1024; // 1.7MB

        // Compress the image until it is below the maximum size
        let compressedFile = result;
        while (compressedFile.size > maxSize) {
          const compressedBlob = result.slice(0, result.size / 2, 'image/jpeg');
          compressedFile = new File([compressedBlob], 'compressed.jpg', { type: 'image/jpeg' });
        }

        resolve(compressedFile);
      },
      error(err) {
        reject(err);
      },
    });
  });
}