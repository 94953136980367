import React , { useRef, useState } from "react";
import Input from '@mui/joy/Input';
import Autocomplete from '@mui/joy/Autocomplete';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import "./form.css";
import { sources, wilayas } from "../constants/global";
import { Typography } from "@mui/joy";
import { participateInContest } from "../api/participants";
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Le prénom doit comporter au moins 3 caractères')
    .required('Le prénom est requis'),
  lastName: Yup.string()
    .min(3, 'Le nom doit comporter au moins 3 caractères')
    .required('Le nom est requis'),
  phoneNumber: Yup.string()
    .matches(/^\d{10}$/, 'Le numéro de téléphone doit comporter 10 chiffres')
    .required('Le numéro de téléphone est requis'),
  wilaya: Yup.string()
    .oneOf(wilayas)
    .required('la Wilaya est obligatoire'),
  source: Yup.string()
    .oneOf(sources)
    .required('la source est obligatoire'),
  link: Yup.string()
    .url('Le lien du profil doit être valide')
    .required('Lien obligatoire'),
  image : Yup.mixed()
    .test('fileType', 'Seules les images sont autorisées', (value) => {
      return value ? value.type.startsWith('image/') : true;
    })
    .required('Image du produit est obligatoire'),
});


const ContestForm = ({name}) => {
  const { addToast } = useToasts();
  const imageInputRef = useRef(null);
  const [disabled, setDisabled] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      wilaya: '',
      link: 'https://facebook.com',
      source: '',
      image: '',
    },
    validationSchema,
    onSubmit: async (values , { resetForm }) => {
      setDisabled(true);
      try {
        const result = await participateInContest(values );
        // Perform any necessary form submission logic here
        if(result.message === 'ok') {
          addToast(`Toutes nos félicitations! vous avez participé à ${name}.`, { appearance: "success" });
        }else if (result.message === 'no_ongoing_contest') {
          addToast(`Nous sommes désolés, le concours est maintenant terminé.`, { appearance: "error" });
        } else if (result.message === 'phone_number_expired') {
          addToast(`Ce numéro de téléphone a déjà été utilisé.`, { appearance: "error" });
        } else {
          addToast(`Essayer à nouveau! Quelque chose s'est mal passé.`, { appearance: "error" });
        }
        try {
          const fileInput = document.getElementById("image");
          fileInput.value = "";
          fileInput.files = new FileList();
        } catch (error) {
          console.log(error);
        }
        resetForm();
        setDisabled(false);  
      } catch (error) {
        setDisabled(false);
        addToast(`Essayer à nouveau! Quelque chose s'est mal passé.`, { appearance: "error" });
      }
    },
  });

  return (
    <form className="form-style" onSubmit={formik.handleSubmit}>
      <Typography level="h5" className="title-form">{`Participez à ${name}`}</Typography>
      <Input
        id="firstName"
        className="form-input"
        size="lg"
        placeholder="Nom"
        value={formik.values.firstName}
        onChange={formik.handleChange}
      />
      {formik.touched.firstName && formik.errors.firstName && <div className="input-error" ><span style={{color:'red'}}>*&nbsp;&nbsp;</span>{formik.errors.firstName}</div>}
      <Input
        id="lastName"
        className="form-input"
        size="lg"
        placeholder="Prénom"
        value={formik.values.lastName}
        onChange={formik.handleChange}
      />
      {formik.touched.lastName && formik.errors.lastName && <div className="input-error"><span style={{color:'red'}}>*&nbsp;</span>{formik.errors.lastName}</div>}
      <Input
        id="phoneNumber"
        size="lg"
        className="form-input"
        placeholder="Numéro de téléphone"
        value={formik.values.phoneNumber}
        onChange={formik.handleChange}
      />
      {formik.touched.phoneNumber && formik.errors.phoneNumber && <div className="input-error"><span style={{color:'red'}}>*&nbsp;</span>{formik.errors.phoneNumber}</div>}
      <Autocomplete
        id="wilaya"
        placeholder="Wilaya"
        className="form-input"
        size="lg"
        options={wilayas}
        value={formik.values.wilaya}
        onChange={(event, newValue) => {
          formik.setFieldValue('wilaya',newValue);
        }}
      />
      {formik.touched.wilaya && formik.errors.wilaya && <div className="input-error"><span style={{color:'red'}}>*&nbsp;</span>{formik.errors.wilaya}</div>}
      {/* <Input
        id="address"
        size="lg"
        className="form-input"
        placeholder="Adresse"
        value={formik.values.address}
        onChange={formik.handleChange}
      /> */}
      {/* {formik.touched.address && formik.errors.address && <div className="input-error"><span style={{color:'red'}}>*&nbsp;</span>{formik.errors.address}</div>} */}
      {/* <Input
        id="link"
        size="lg"
        className="form-input"
        placeholder="Lien Facebook ou Instagram"
        value={formik.values.link}
        onChange={formik.handleChange}
      />
      {formik.touched.link && formik.errors.link && <div className="input-error"><span style={{color:'red'}}>*&nbsp;</span>{formik.errors.link}</div>} */}
      <Autocomplete
        id="source"
        placeholder="Où avez-vous vu notre annonce ?"
        className="form-input"
        size="lg"
        options={sources}
        value={formik.values.source}
        onChange={(event, newValue) => {
          formik.setFieldValue('source',newValue);
        }}
      />
      {formik.touched.source && formik.errors.source && <div className="input-error"><span style={{color:'red'}}>*&nbsp;</span>{formik.errors.source}</div>}
      <Divider />
      <Typography level="h5" className="label-form">Finalement : Sélectionnez une Image du produit WAFA</Typography>
      <Input
        ref={imageInputRef}
        id="image"
        size="lg"
        className="form-input"
        placeholder="Image du produit"
        type="file"
        onChange={(event) => {
          formik.setFieldValue('image', event.currentTarget.files[0]);
        }}
      />
      {formik.touched.image && formik.errors.image && <div className="input-error"><span style={{color:'red'}}>*&nbsp;</span>{formik.errors.image}</div>}
      <Button 
        onClick={formik.handleSubmit} 
        className="form-btn" 
        loading={disabled}
        variant="solid">
          Soumettre
      </Button>
    </form>
  );
};

export default ContestForm;